.body,
html {
    overflow: hidden;
}

.scrollable__container {
    overflow-y: auto;
    max-height: calc(100vh - 278px);
}

// Custom Scrollbar
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffb200;
}

.centered__parent {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.login__backdrop {
    z-index: 1201;
    background: #eeeeee;
    .login__spinner {
        position: absolute;
    }
}

.snackbar__container {
    margin: 2px;
}

.changepassword {
    &__password {
        margin-bottom: 10px;
    }

    &__confirm {
        margin-bottom: 20px;
    }
}

.forgotpassword {
    &__text {
        margin-bottom: 20px;
    }

    &__button {
        margin-bottom: 16px;
        margin-right: 15px;
    }

    &__icon {
        margin-right: 5px;
        margin-bottom: 2px;
    }
}

.register {
    &__strength {
        width: 85px;
        height: 8px;
        border-radius: 7px;
    }
}

.profile {
    &__textfield {
        margin-bottom: 10px;
    }

    &__button {
        margin-left: auto;
        margin-top: 20px;

        &__container {
            display: flex;
        }
    }
}

.avatar__container {
    text-align: center;
    margin: auto;
    position: relative;
    border: 1px solid transparent;
    overflow: hidden;

    .avatar__img {
        cursor: pointer;
        height: 250px;
        width: 250px;
        margin: auto;

        &:hover {
            opacity: 0.5;
            transition: 0.5s;
        }
    }

    .avatar__input {
        display: none;
    }

    .avatar__upload {
        text-transform: uppercase;
        color: var(--primary);
        margin-bottom: 25px;
        font-size: 0.7rem;
        font-weight: bold;
    }

    .avatar__contain {
        img {
            object-fit: contain;
        }
    }
    .avatar__cover {
        img {
            object-fit: cover;
        }
    }
}
