.polls__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.poll__container {
    display: flex;
    flex-direction: column;

    .isActive__toggle {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 15px;
    }

    .question__input {
        margin-bottom: 25px;
    }

    .answers__input {
        margin-bottom: 25px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .answers__textfield {
            width: 90%;
            padding-right: 20px;
        }
    }

    .add__answer__button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-right: 50px;
    }
}

.add__poll__button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    //padding-right: 50px;
    padding-bottom: 10px;
}

.dialog__options {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.error__message {
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.poll-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 75px;

    .poll-entry-count {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 600;
        color: rgba(50, 50, 50, 0.95);
        font-family: "Arial, sans-serif";
        letter-spacing: 1.2px;
    }
}

.chart__container {
    width: 65%;
    height: 65%;
    display: flex;
    flex-direction: row;

    .legend {
        padding-top: 5%;
        font-family: "Arial, sans-serif";

        .legend__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 8px;

            .legend__color {
                width: 25px;
                height: 25px;
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            }

            .legend__label {
                padding-left: 12px;
                font-size: 14px;
                color: rgba(50, 50, 50, 0.85);
                font-weight: 500;
            }
        }
    }
}
