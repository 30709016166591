.sidemenu {
    &__drawer {
        .MuiDrawer-paper {
            width: 260px;
            background: #ffffff;
            color: #616161;
            border-right: none;

            @media (min-width: 900px) {
                top: 88px;
            }
        }
    }

    &__navitem__error {
        width: 0.4rem;
    }

    &__navcollapse {
        margin-top: "auto";
        margin-bottom: "auto";

        &__list {
            position: relative;

            &:after {
                content: "";
                position: absolute;
                left: 32px;
                top: 0;
                height: 100%;
                width: 1px;
                opacity: 1;
                background: $primaryLight;
            }
        }
    }

    &__menulist {
        height: calc(100vh - 88px);
        padding-left: 16px;
        padding-right: 16px;
    }

    &__logo {
        @media (min-width: 0px) {
            display: block;
        }
        @media (min-width: 900px) {
            display: none;
        }

        &__alignment {
            display: flex;
            padding: 16px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__mobile {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.loader__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1301;
    width: 100%;
}

.authwrapper__container {
    background-color: $primaryLight;
    min-height: 100vh;
}

.authcardwrapper {
    &__container {
        @media (min-width: 0px) {
            max-width: 400px;
            margin: 20px;
        }

        @media (min-width: 900px) {
            margin: 24px;
        }

        @media (min-width: 1200px) {
            max-width: 475px;
        }
    }
    &__box {
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        -webkit-flex-basis: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;

        @media (min-width: 600px) {
            padding: 24px;
        }

        @media (min-width: 0px) {
            padding: 16px;
        }
    }
}

.profileSection__logo {
    img {

        object-fit: contain !important;
    }
}